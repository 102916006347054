.button {
    background: var(--green);
    border-radius: calc(var(--uint) * 1);
    padding: calc(var(--uint) * 2.5) calc(var(--uint) * 4);
    color: var(--white);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.028em;
    min-width: 64px;
    font-size: var(--caption);
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.button:not(:disabled):hover {
    background: var(--greenDark);
}

.button span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.button:not(:disabled):active {
    opacity: 0.85;
}

.button:disabled {
    background: var(--grey);
    color: var(--black);
}

.fullWidth {
    width: 100%;
}