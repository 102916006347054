:root {
    --uint: 8px;
    --divider: rgba(0, 0, 0, 0.12);
    --disabled: rgba(0, 0, 0, 0.02);
    --grey: #f3f3f3;
    --error: #9c1208;

    --black: #000000;
    --textSecondary: #5e5e5e;
    --white: #FFFFFF;
    --green: #28a745;
    --greenDark: #208437;
    --primary: #d3180b;

    --body1: 1rem;
    --body2: 0.875rem;
    --caption: 0.75rem;

    --boxShadow: 3px 1px 22px -3px rgba(34, 60, 80, 0.05);
}

html {
    font-size: 18px;
}

body {
    color: var(--black);
    background-color: var(--white);
    font-family: "Muller", sans-serif;
    font-weight: 400;
    line-height: 1.3;
    overflow-x: hidden;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.5rem;
}

h4 {
    font-size: 1.8rem;
}

h5 {
    font-size: 1.3rem;
}

h6 {
    font-size: 1.15rem;
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 16px;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }
    
    h3 {
        font-size: 1.75rem;
    }
    
    h4 {
        font-size: 1.5rem;
    }
    
    h5 {
        font-size: 1.25rem;
    }
    
    h6 {
        font-size: 1.15rem;
    }
}