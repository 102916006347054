@font-face {
    font-family: "Muller";
    src: url("../fonts/Muller/Muller-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Muller";
    src: url("../fonts/Muller/Muller-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Muller";
    src: url("../fonts/Muller/Muller-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

* {
    box-sizing: border-box;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

input,
button {
    border: none;
    outline: none;
}

button,
a {
    cursor: pointer;
    transition: 0.3s all ease;
}