.loaderWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid var(--primary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary) transparent transparent transparent;
}
  
.loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loaderText {
    margin-top: calc(var(--uint) * 2);
    font-size: var(--caption);
}

.smallLoader {
    width: 16px;
    height: 16px;
}

.smallLoader div {
    border: 2px solid var(--white);
    border-color: var(--white) transparent transparent transparent;
}