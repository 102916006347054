.logo {
    width: 80px;
    height: 80px;
}

.header {
    position: relative;
    padding-top: 5%;
}


.language {
    position: absolute;
    top: 32px;
    right: 32px;
}

.head {
    text-align: center;
}

.head h4 {
    margin-top: calc(var(--uint) * 1);
}

.main {
    margin-top: calc(var(--uint) * 3);
}

.wrap {
    margin: 0 auto;
    background-color: var(--white);
    border-radius: calc(var(--uint) * 1);
    max-width: 700px;
    padding: calc(var(--uint) * 3);
    box-shadow:  var(--boxShadow);
}

.bg {
    z-index: -1;
    opacity: 0.4;
    width: 50%;
    top: 5%;
    left: 25%;
    position: fixed;
}

.footer {
    margin-top: calc(var(--uint) * 3);
    padding-bottom: calc(var(--uint) * 3);
    text-align: center;
    font-size: var(--body2);
}

@media only screen and (max-width: 600px) {
    .bg {
        width: 100%;
        top: 5%;
        left: 0%;
        position: fixed;
    }

    .logo {
        width: 60px;
        height: 60px;
    }

    .header {
        padding-top: calc(var(--uint) * 4);
    }

    .language {
        display: flex;
        justify-content: center;
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: calc(var(--uint) * 3);
    }
}