.backLink {
    display: inline-flex;
    align-items: center;
    color: var(--textSecondary);
}

.backLink:hover {
    color: var(--textPrimary);
}

.backLink svg {
    margin-right: calc(var(--uint) * 0.5);
    width: 20px;
    height: 20px;
}

[data-theme='blacklime'] .backLink svg {
    color: var(--secondaryLight);
}