.root {
    position: relative;
}

.button {
    display: inline-flex;
    align-items: center;
    font-size: var(--body2);
    border: 1px solid var(--divider);
    outline: none;
    background: transparent;
    border-radius: var(--uint);
    padding: calc(var(--uint) * 1) calc(var(--uint) * 2);
}

.button > svg {
    width: 22px;
    height: auto;
    margin-right: calc(var(--uint) * 0.5);
}

.button::after {
    content: " ";
    width: 0;
    height: 0;
    display: inline-block;
    margin-left: 5px;
    border-top: 5px solid #4d4d4d;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0;
}

.buttonOpened::after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #4d4d4d;
}

.list {
    background-color: var(--white);
    overflow: hidden;
    width: 100%;
    position: absolute;
    border-radius: var(--uint);
    top: calc(100% + var(--uint));
    font-size: var(--body2);
    border: 1px solid var(--divider);
}


.list > li {
    cursor: pointer;
    padding: var(--uint);
}

.list > li:hover {
    background: var(--divider);
}

.list > li > svg {
    margin-right: calc(var(--uint) * 0.5);
    width: 22px;
    height: auto;
}