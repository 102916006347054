.input p {
    margin-bottom: calc(var(--uint) * 0.5);
    font-size: var(--body2);
}

.input input {
    border-radius: var(--uint);
    padding: calc(var(--uint) * 1.5) calc(var(--uint) * 2);
    color: var(--black);
    border: 1px solid var(--divider);
}

.field {
    position: relative;
}

.copy {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    border: 1px solid var(--divider);
    padding: var(--uint);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
}

.copy:hover {
    border-color: var(--primary);
    color: var(--primary);
}

.copy svg {
    width: 14px;
    height: auto;
}

.input textarea {
    border-radius: var(--uint);
    padding: calc(var(--uint) * 2);
    outline: none;
    resize: none;
    min-height: 200px;
    border: 1px solid var(--divider);
    color: var(--black);
    line-height: 1.3;
}

.input textarea:disabled,
.input input:disabled {
    background-color: var(--disabled);
    opacity: 1;
}

.fullWidht input,
.fullWidht textarea {
    width: 100%;
}

.fullWidht {
    width: 100%;
}

.fieldWithCopy input {
    border-radius: var(--uint);
    padding: calc(var(--uint) * 2);
    color: var(--black);
    padding-right: 52px;
}

.inputBorder input {
    border: 1px solid var(--divider);
}