.container {
    max-width: 1080px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 0 calc(var(--uint) * 3);
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 0 calc(var(--uint) * 2);
    }
}